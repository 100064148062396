@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");

/* Import Mulish font */
@import "~@fontsource/mulish";

:root {
  --base-font-family: "Mulish", sans-serif;

  --bg-main-color: #fffcf8;
  --bg-gray-color: #f8f5f2;
  --color-white: #ffffff;
  --color-black: #000;
  --border-color: #e8e7e7;
  --border-orange-color: #f29353;

  --primary-text-black: #303030;
  --secondary-text-black: #272927;

  --primary-text-gray: #6a6969;
  --secondary-text-gray: #a1a1a1;
  --tertiary-text-gray: #818181;

  --link-text-color: #a0acee;
  --color-text-blue: #1d90d0;
}

body {
  font-family: var(--base-font-family) !important;
  overflow-x: hidden;
  background-color: var(--bg-main-color) !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 16px;
}

input::-webkit-input-placeholder {
  color: var(--primary-text-black) !important;
}

.MuiTypography-root,
.MuiInputBase-root,
.MuiMenuItem-root {
  font-family: var(--base-font-family) !important;
}

.max-width-100 {
  max-width: 100px;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.flex-direction-column {
  flex-direction: column;
}

/* margin declaration */

.ml-10px {
  margin-left: 10px !important;
}

.ml-15px {
  margin-left: 15px !important;
}

.ml-0px {
  margin-left: 0px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-16px {
  margin-top: 16px !important;
}

.mt-20px {
  margin-top: 20px;
}

.mt-40px {
  margin-top: 40px;
}

.mt-0px {
  margin-top: 0px !important;
}

.mb-0px {
  margin-bottom: 0px !important;
}

.mb-16px {
  margin-bottom: 16px;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.mb-35px {
  margin-bottom: 35px;
}

.mb-40px {
  margin-bottom: 40px;
}

.mb-10px {
  margin-bottom: 10px;
}

.pt-0px {
  padding-top: 0px !important;
}

.pb-0px {
  padding-bottom: 0px !important;
}

.pt-10px {
  padding-top: 10px !important;
}

.pb-10px {
  padding-bottom: 10px;
}

.pl-0px {
  padding-left: 0px !important;
}

.MuiCardContent-root {
  padding-bottom: 16px !important;
}

.border-left-orange {
  border-left: 3px solid var(--border-orange-color);
}

.h-100 {
  height: 100%;
}

.h-45px {
  height: 45px;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.d-none {
  display: none;
}

.bg-pink {
  background-color: #e4aeae99 !important;
}

.border-pink {
  border-right: 3px solid #ca6b6e99;
}

.border-blue {
  border-right: 3px solid #96c1d3;
}

.border-yellow {
  border-right: 3px solid #ebde90;
}

.bg-blue {
  background-color: #aed4e499 !important;
}

.bg-orange {
  background-color: #e4dbae99 !important;
}

/* font size define */

.fs-20px {
  font-size: 20px !important;
}

/* sidebar UI */
.sideIcon {
  max-width: 100px;
  object-fit: contain;
  height: 30px;
  /* width: 70%; */
}

.sidebarSection .sideListSection .MuiButtonBase-root.MuiListItemButton-root {
  padding-left: 30px;
  padding-right: 30px;
  /* margin-bottom: 7px; */
}

.sidebarSection .MuiPaper-root {
  border-color: var(--border-color);
  /* box-shadow: 0px 1px 50px 0px #00000014; */
}

.sidebarSection
  .sideListSection
  .MuiButtonBase-root.MuiListItemButton-root
  .MuiTypography-root {
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  text-align: left;
  color: var(--primary-text-black);
}

.sidebarSection .sidebarBottomLogos img {
  width: 70%;
}

.sidebarSection .sidebarRights {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 700;
  line-height: 18.83px;
  text-align: left;
  white-space: pre-wrap;
  padding: 10px 13px;
}

.sidebarSection .sidebarFooter {
  position: fixed;
  bottom: 25px;
  width: 240px;
}

.sidebarSection.sidebarClose .sidebarFooter {
  display: none;
}

.sidebarSection.sidebarClose
  .sideListSection
  .MuiButtonBase-root.MuiListItemButton-root
  .MuiTypography-root {
  white-space: inherit !important;
}

.headerClose .menuIconCss,
.HeaderMain .menuIconCss {
  fill: var(--primary-text-gray) !important;
}

/* header ui */

.HeaderMain {
  background-color: var(--bg-main-color) !important;
  box-shadow: none !important;
  padding-top: 40px;
  padding-bottom: 20px;
}

/* .HeaderMain .headerHeading {

    display: flex;
    justify-content: center;
    align-items: center;

} */

.HeaderMain .headerHeading .logoImg {
  height: 60px;
}

.HeaderMain .headerSubHeading {
  color: var(--primary-text-black);
  font-size: 24px;
  font-weight: 700;
  line-height: 35.14px;
  text-align: left;
  white-space: pre-wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* margin-left: 20px; */
}

.HeaderMain .headerUpdatedDate {
  font-size: 14px;
  font-weight: 600;
  line-height: 20.08px;
  text-align: left;
  color: var(--primary-text-gray);
}

.HeaderMain .headerMainButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.HeaderMain .headerMainButtons button,
.HeaderMain .headerMainButtons button:hover {
  box-shadow: 0px 1px 50px 0px #00000005;
  color: var(--color-white);
  background-color: var(--color-white);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  height: 40px;
  padding: 11px 0px;
  /* gap: 10px; */
  opacity: 0px;
  min-width: 45px;
}

.HeaderMain .headerMainButtons button img {
  width: 20px;
}

.HeaderMain .headerMainButtons .languageSelectField {
  background-color: var(--color-white);
  border-radius: 12px;
  border-color: var(--border-color);
}

.HeaderMain .headerMainButtons .languageSelectField .languageIcon {
  max-width: 25px;
}

.HeaderMain
  .headerMainButtons
  .languageSelectField
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color);
  border-radius: 12px;
}

.HeaderMain .headerMainButtons .languageSelectField .MuiSelect-select {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--color-black);
  font-size: 17px;
  font-weight: 400;
  text-align: left;
}

.HeaderMain .headerMainButtons .languageSelectField .placeHolder {
  color: var(--color-black);
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

/* main page ui */
.mainStructurePage {
  margin-top: 155px;
}

/* first page ui */

.homePageCardSubSection .mapSection {
  min-height: 410px;
}

.homePageCardSubSection .cardHeading {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 700;
  line-height: 25.1px;
  text-align: left;
}

.homePageCardSubSection .leftSideCard button.lightblueButton {
  background-color: #e1f3fb;
  border-radius: 12px;
  width: 50px;
  height: 50px;
  /* opacity: 0px; */
  /* padding-bottom: 0px; */
}

.homePageCardSubSection button.lightOrangeButton {
  background-color: #fbece1;
  border-radius: 12px;
  width: 50px;
  height: 50px;
  /* opacity: 0px; */
}

.homePageCardSubSection .leftSideCard .MuiCard-root,
.homePageCardSubSection .rightSideCard .MuiCard-root,
.homePageCardSubSection .MuiCard-root,
.commonPageCardSubSection .MuiCard-root {
  box-shadow: 0px 1px 50px 0px #00000005;
  border: 1px solid var(--border-color);
  border-radius: 12px;
}

.homePageCardSubSection .rightSideCard .selectFieldSection .countrySelectField {
  background-color: var(--color-white);
  border-radius: 12px;
  border-color: transparent;
  margin-left: 12px;
}

.homePageCardSubSection .rightSideCard .selectFieldSection .searchIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  fill: var(--tertiary-text-gray);
}

.homePageCardSubSection .rightSideCard .countrySelectField .countryName {
  color: var(--primary-text-black);
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  text-align: left;
}

.homePageCardSubSection .rightSideCard .selectFieldSection .MuiCard-root {
  background-color: var(--bg-gray-color);
  border-radius: 12px;
  border-color: transparent;
}

.homePageCardSubSection
  .rightSideCard
  .selectFieldSection
  .countrySelectField
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.homePageCardSubSection .rightSideCard .selectFieldSection .MuiInputBase-root {
  font-family: var(--base-font-family) !important;
}

.homePageCardSubSection .rightSideCard .countrySelectField .MuiSelect-select {
  padding-top: 10px;
  padding-bottom: 10px;
}

.homePageCardSubSection .leftSideCard button.lightblueButton.lightredButton {
  background-color: #f3aead5e;
}

.homePageCardSubSection .leftSideCard button.lightblueButton.lightorangeButton {
  background-color: #f3cbad5e;
}

.homePageCardSubSection .leftSideCard button.lightblueButton img {
  max-width: 35px;
}

.homePageCardSubSection button.lightOrangeButton img {
  max-width: 35px;
}

.homePageCardSubSection .leftSideCard .leftCardHeading {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 600;
  line-height: 20.08px;
  text-align: left;
  margin-left: 10px;
}

.homePageCardSubSection .leftSideCard .indicatorCount {
  color: var(--secondary-text-black);
  font-size: 32px;
  font-weight: 400;
  line-height: 40.16px;
  text-align: left;
}

.homePageCardSubSection .leftSideCard .indicatorDate {
  font-size: 10px;
  font-weight: 600;
  line-height: 12.55px;
  text-align: left;
  color: var(--tertiary-text-gray);
}

.homePageCardSubSection .leftSideCard .indicatorSubCount {
  color: #eb7170;
  font-size: 11px;
  font-weight: 600;
  line-height: 12.55px;
  text-align: left;
}

.homePageCardSubSection .leftSideCard .indicatorSubCount.color-green {
  color: #47a440 !important;
}

.homePageCardSubSection .cardHeaders {
  color: var(--color-black);
  font-size: 15px;
  font-weight: 600;
  line-height: 20.08px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.homePageCardSubSection .cardCounts {
  color: var(--secondary-text-black);
  font-size: 32px;
  font-weight: 400;
  line-height: 40.16px;
  text-align: center;
}

.homePageCardSubSection .infoImg {
  max-width: 25px;
}

.homePageCardSubSection .cardParaComponent .cardHeading {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 700;
  line-height: 25.1px;
  text-align: left;
}

.homePageCardSubSection .cardParaComponent p {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 20.08px;
  text-align: left;
}

/* community Page */

.commonPageCardSubSection .defineCountryHeight {
  height: 810px;
}

.commonPageCardSubSection .defineReportHeight {
  height: 175px;
  overflow: auto;
}

.commonPageCardSubSection .defineCountryInfoHeight {
  height: 995px;
  overflow-y: auto;
}

.commonPageCardSubSection .defineScoreHeight {
  height: 165px;
}

.commonPageCardSubSection .defineCountryUNCardHeight {
  height: 810px;
  overflow-y: auto;
}

.commonPageCardSubSection .defineReportRightCardHeight {
  height: 165px;
}

.commonPageCardSubSection .MuiTypography-root {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  text-align: left;
}

.commonPageCardSubSection .keyPara {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 20.08px;
  text-align: left;
}

.commonPageCardSubSection .lightShadeButton {
  background-color: #d0fbff;
  border-radius: 12px;
  width: 50px;
  height: 50px;
}

.commonPageCardSubSection .redShadeBg {
  background-color: #f3aead5e;
}

.commonPageCardSubSection .orangeShadeBg {
  background-color: #f8debd;
}

.commonPageCardSubSection button.lightShadeButton img {
  max-width: 35px;
}

.commonPageCardSubSection .ComCardHeading {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 600;
  line-height: 20.08px;
  text-align: left;
  margin-left: 10px;
}

.commonPageCardSubSection .indicatorCount {
  color: var(--secondary-text-black);
  font-size: 32px;
  font-weight: 400;
  line-height: 40.16px;
  text-align: left;
}

.commonPageCardSubSection .indicatorDate {
  font-size: 10px;
  font-weight: 600;
  line-height: 12.55px;
  text-align: left;
  color: var(--tertiary-text-gray);
}

.commonPageCardSubSection .indicatorSubCount {
  color: #eb7170;
  font-size: 11px;
  font-weight: 600;
  line-height: 12.55px;
  text-align: left;
}

.commonPageCardSubSection .indicatorSubCount.color-green {
  color: #47a440 !important;
}

.commonPageCardSubSection .color-grey {
  color: var(--secondary-text-gray);
  font-size: 10px;
  font-weight: 600;
  line-height: 12.55px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.commonPageCardSubSection .infoImg {
  max-width: 20px;
}

.commonPageCardSubSection .selectFieldSection .searchIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  fill: var(--tertiary-text-gray);
}

.commonPageCardSubSection .selectFieldSection .countrySelectField {
  background-color: var(--color-white);
  border-radius: 12px;
  border-color: transparent;
  margin-left: 12px;
}

.commonPageCardSubSection .selectFieldSection .MuiInputBase-root {
  font-family: var(--base-font-family) !important;
}

.commonPageCardSubSection .countrySelectField .MuiSelect-select {
  padding-top: 10px;
  padding-bottom: 10px;
}

.commonPageCardSubSection .countrySelectField .countryName {
  color: var(--primary-text-black);
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  text-align: left;
}

.commonPageCardSubSection
  .selectFieldSection
  .countrySelectField
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.countryDropdown {
  background-color: var(--bg-gray-color);
  padding: 8px;
  border-radius: 12px;
  margin-top: 20px;
  padding-top: 1px;
}

.commonPageCardSubSection
  .listCountries
  .MuiListItemText-root
  .MuiTypography-root {
  color: var(--color-black);
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.commonPageCardSubSection .listCountries .MuiListItemIcon-root img {
  max-width: 30px;
}

.commonPageCardSubSection .listCountries .MuiListItemIcon-root {
  min-width: 50px;
}

.commonPageCardSubSection .listCountries .countryactive {
  background-color: var(--bg-gray-color);
  border-radius: 8px;
}

.commonPageCardSubSection .listCountryHeader {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.commonPageCardSubSection .reportHeading {
  color: var(--color-black);
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
  text-align: left;
  padding-bottom: 9px;
}

.commonPageCardSubSection .reportLink {
  color: var(--link-text-color);
  font-size: 12px;
  font-weight: 700;
  line-height: 17.57px;
  text-align: left;
  word-wrap: break-word;
}

.commonPageCardSubSection .summaryCountryHeading {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 700;
  line-height: 25.1px;
  text-align: left;
}

.commonPageCardSubSection .summarySection {
  color: var(--color-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 17.57px;
  text-align: left;
}

.commonPageCardSubSection .dashboardCountrySection {
  /* height: calc(100vh + 321px); */

  padding-left: 15px;
  padding-right: 15px;
}

/* unhlm ui */
.commonPageCardSubSection .unhlmHeading {
  color: var(--color-black);
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  padding-bottom: 8px;
}

.unhlmPageCardSubSection .keyParaSection {
  color: var(--color-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.commonPageCardSubSection .keyParaSection {
  color: var(--color-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.commonPageCardSubSection button.lightOrangeButton {
  background-color: #fbece1;
  border-radius: 12px;
  width: 50px;
  height: 50px;
}

.commonPageCardSubSection button.lightOrangeButton img {
  max-width: 35px;
}

.commonPageCardSubSection .cardHeaders {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  text-align: left;
}

.commonPageCardSubSection .cardCounts {
  color: var(--secondary-text-black);
  font-size: 32px;
  font-weight: 400;
  line-height: 40.16px;
  text-align: center;
}

.feedbackSectionPage .countryDropdown .MuiSelect-select {
  color: var(--primary-text-black);
  font-size: 17px;
  font-weight: 700;
  line-height: 22.59px;
  text-align: left;
}

.feedbackSectionPage .feedbackMsg {
  background-color: var(--bg-gray-color);
  padding: 13px;
  border-radius: 12px;
  margin-top: 20px;
}

.feedbackSectionPage .feedbackMsg .MuiFormControl-root {
  background-color: var(--color-white);
  border-radius: 12px;
  margin-right: 5px;
  margin-top: 8px;
}

.feedbackSectionPage
  .feedbackMsg
  .MuiFormControl-root
  fieldset.MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.feedbackSectionPage .feedbackInfo {
  color: var(--color-text-blue);
  font-size: 18px;
  font-weight: 400;
  line-height: 22.59px;
  text-align: left;
}

.feedbackSectionPage .feedbackDetails span {
  font-size: 17px;
  font-weight: 700;
  line-height: 22.59px;
  text-align: left;
  color: var(--color-text-blue);
  margin-left: 14px;
}

.feedbackPara {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.59px;
  text-align: left;
}

.footer {
  background-color: #fcf1e3;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 29px;
}

.footer .footerImg {
  gap: 10px;
  display: flex;
}

.footer .footerImg a {
  line-height: normal;
}

.mt-5 {
  margin-top: 5px !important;
}

.commonText {
  color: var(--color-black);
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  padding-bottom: 8px;
}

.cardHeading {
  color: var(--color-black);
  font-size: 20px !important;
  font-weight: 700;
  line-height: 25.1px;
  text-align: left;
}

.btn-submit {
  background-color: #26c6f9;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  border-color: #26c6f9;
  outline: none;
  appearance: none;
  border: 1px solid #26c6f9;
  cursor: pointer;
}

.btn-clear {
  background-color: #cc121b;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  border-color: #26c6f9;
  outline: none;
  appearance: none;
  border: 1px solid #cc121b;
  margin-left: 10px;
  cursor: pointer;
}

/*********CLM Dashboard styling********/

.trendcharts {
  display: flex;
  gap: 5px;
  margin: 10px;
}

.trendcharts .chartswitchlink.active {
  background-color: #2196f3;
  border-color: #2196f3;
  padding: 6px 10px;
  border-radius: 15px;
  border: 0;
  color: #fff;
}

.trendcharts .chartswitchlink {
  background-color: #cacaca;
  padding: 6px 10px;
  border-radius: 15px;
  border: 0;
  color: #000;
}

.clmdashboardcards .card .card-header {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137254902);
  border-radius: 0;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 5px;
  padding: 0.75rem 1.25rem;
  background-color: #2196f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commonPageCardSubSection.feedbackSectionPage .MuiCard-root {
  border-radius: 0;
}

.mapseccard .clmseccards {
  padding: 0;
}

.commonPageCardSubSection.feedbackSectionPage .maincardcontentclm {
  margin-left: 10px;
  margin-right: 10px;
}

.clmdashboardcards .card .card-header .tooltipdivmain {
  display: inline-block;
  margin-bottom: 0;
  float: right;
}

.clmdashboardcards .chaldropdiv {
  display: inline-block;
  float: right;
}

.clmdashboardcards .dropdownicon {
  height: 10px;
  width: auto;
}

.clmdashboardcards .chaldropdiv.trendchartdropdown button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #2196f3;
  border: 0;
  color: #fff;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  inset: 0px auto auto 0px;
  transform: translate(1226px, 477px);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #23282c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;
}

.chaldropdiv .dropdown-item {
  border-radius: 10px;
  border-bottom: 1px solid #707070;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
  padding-left: 2px;
  color: #505050;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: capitalize;
  background-color: #fff;
  position: relative;
  padding: 10px 20px;
  text-decoration: none;
}

.chaldropdiv .dropdown-item:last-child {
  border-bottom: none;
}

.clmdashboardcards .percent-number-button {
  margin: 10px;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}

.clmdashboardcards .chartswitchlink.active {
  background-color: #2196f3;
  border-color: #2196f3;
  padding: 6px 10px;
  border: 0;
  color: #fff;
}

.clmdashboardcards .chartswitchlink {
  background-color: #cacaca;
  padding: 6px 10px;
  border: 0;
  color: #000;
}

.cursorpointer {
  cursor: pointer !important;
}

/********Feedback page styling*********/

.feedbackdatafilters{
  display: flex;
    gap: 10px;
}

.feedbackdatafilters button{
  background-color: #2196f3;
  border-color: #2196f3;
  padding: 6px 10px;
  border: 0;
  color: #fff;
}

.feedbacktablecard{
  padding-left: 10px;
  padding-right: 10px;
}

.feedbacktablecard .table{
  width: 100%;
    overflow-x: auto;
    margin: 20px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    border-bottom: none;
}

.feedbacktablecard thead{
  background-color: #f4f4f4;
    color: #444;
    padding: 12px;
    text-align: left;
    border-bottom: 2px solid #ddd;
}

.feedbacktablecard tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.feedbacktablecard tbody tr:nth-child(even) {
  background-color: #fff;
}

.feedbacktablecard tbody td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd
}

.feedbacktablecard thead th{
  border-right: 1px solid #ddd;
  padding: 5px;
}

.feedbacktablecard thead th:last-child,
.feedbacktablecard tbody td:last-child {
  border-right: none;
}

.feedbacktablecard .dropdown-item {
  border-radius: 10px;
  border-bottom: 1px solid #707070;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
  padding-left: 2px;
  color: #505050;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: capitalize;
  background-color: #fff;
  position: relative;
  padding: 10px 20px;
  text-decoration: none;
}

.feedbacktablecard .dropdown-item:last-child {
  border-bottom: none;
}

.commonPageCardSubSection.feedbackSectionPage .feedbackgraphsgrid{
  margin-top: 20px;
}

/* Ensure the pagination container has a good layout and spacing */
.pagination-controls {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center;
  margin-top: 20px; /* Add margin for spacing above the controls */
  gap: 10px; /* Add some space between buttons */
  font-family: Arial, sans-serif; /* Set a clean font for pagination */
}

/* Styling for the pagination buttons */
.pagination-btn {
  background-color: #26c6f9; /* Blue background */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 10px;
    display: flex;
    justify-content: center;
}

/* Styling for the active and disabled buttons */
.pagination-btn:disabled {
  background-color: #b3e0f9; /* Light blue background for disabled buttons */
  cursor: not-allowed;
  width: 10px;
    display: flex;
    justify-content: center;

}

/* Hover effect for active buttons */
.pagination-btn:hover:not(:disabled) {
  background-color: #039be5; /* Darker blue on hover */
  transform: scale(1.1); /* Slight scale effect */
}

/* Styling for the page information text */
.pagination-info {
  font-size: 16px;
  color: #333;
  margin: 0 10px;
  font-weight: bold;
}

/* Optional: Style for the select dropdown (if you uncomment the dropdown) */
select {
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #f9f9f9;
}

/* Hover effect for the select dropdown */
select:hover {
  background-color: #e9e9e9;
}

/* Responsive design for small screens */
@media (max-width: 600px) {
  .pagination-controls {
    gap: 5px;
  }

  .pagination-info {
    margin-top: 10px;
  }
}