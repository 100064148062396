 /* Large Devices, Wide Screens */
 @media only screen and (min-width : 1200px) {
     .homePageCardSubSection .defineHomeCardHeight {
         height: 145px;
     }

     .displayMobile {
         display: none !important;
     }

     .subCountries-desktop-d-none {
         display: none;
     }
 }

 /* Mobile Devices */
 @media (min-width:320px) and (max-width:767px) {
     /* .mainHomePageSection .MuiDrawer-root {
        display: none;
    } */

     .homePageCardSubSection .rightSideCard .selectFieldSection .MuiCard-root {
         width: 100%;
     }

     .HeaderMain {
         padding-top: 40px;
         padding-bottom: 20px;
     }

     .mainStructurePage {
         margin-top: 170px;
     }

     .HeaderMain .headerMainButtons button,
     .HeaderMain .headerMainButtons button:hover {
         height: 30px;
         min-width: 35px;
     }

     .HeaderMain .headerMainButtons button img {
         width: 15px;
     }

     .HeaderMain .headerSubHeading {
         font-size: 14px;
         line-height: inherit;
         text-align: center;

         justify-content: center;
         align-items: center;
     }

     .HeaderMain .max-width-100.logoImg {
         max-width: 50px;
     }

     .feedbackSectionPage .countryDropdown {
         width: 100%;
     }

     .feedbackDetails .justify-content-center {
         justify-content: flex-start;
     }

     .displayMobile {
         display: block !important;
     }

     .displayDesktop {
         display: none !important;
     }

     .commonPageCardSubSection .keyPara p ul {
         padding-left: 20px;
     }

     .commonPageCardSubSection.feedbackSectionPage .feedbackMsgMobile {
         flex-direction: column;
         gap: 10px;
         align-items: stretch;

     }

     .commonPageCardSubSection.feedbackSectionPage .feedbackMsgMobile .MuiFormControl-root {
         width: 100%;
     }

     .mobile-text-center {
         text-align: center !important;
     }

     .commonPageCardSubSection .defineReportHeight {
         height: auto;
     }

     .commonPageCardSubSection .defineCountryHeight {
         display: none;
     }

     .subCountries-mobile-d-none {
         display: none;
     }

     .commonPageCardSubSection .mobile-DataLink {
         width: 20rem;
     }

     .summarySection ul {
         padding-left: 0px;
     }



 }


 /* ipad  */
 @media (min-width:768px) and (max-width:1024px) {
     .mainStructurePage {
         margin-top: 220px;
     }

     .commonPageCardSubSection.feedbackSectionPage .feedbackMsgMobile {
         flex-direction: column;
         gap: 10px;
         align-items: stretch;
     }

     .feedbackSectionPage .countryDropdown.w-30 {
         width: 100%;
     }

     .feedbackSectionPage .feedbackMsg .MuiFormControl-root {
         width: 100%;
     }

     .homePageCardSubSection .rightSideCard .selectFieldSection .MuiCard-root.w-50 {
         width: 100%;
     }

     .order-2 {
         order: 2;
     }

     .order-1 {
         order: 1;
     }

     .HeaderMain .headerSubHeading {
         font-size: 18px;
         justify-content: center;
         text-align: center;
         margin-left: 18px;
     }

     .HeaderMain .headerMainButtons .languageSelectField {
         width: 40%;
     }


     .subCountries-mobile-d-none {
         display: none;
     }

     .commonPageCardSubSection .mobile-DataLink {
         width: 30rem;
     }

     .HeaderMain .headerMainButtons {
         justify-content: end;
     }

 }